@charset "UTF-8";
@tailwind base;
@tailwind components;
@tailwind utilities;
a,
body {
  text-decoration: none;
}
.nav-list,
ul {
  list-style: none;
}
.rounded-rectangle-1-holder,
.text-5 {
  color: #fff;
  font-size: 1.0625rem;
  text-transform: uppercase;
}
.rounded-rectangle-1-holder,
.text-5,
.text-6 {
  text-transform: uppercase;
}
.rotate,
.top-copy {
  transform: rotate(180deg);
}

@font-face {
  font-family: CustomFont;
  src: url("../images/fonts/GlacialIndifference-Regular.otf") format("woff2"),
    url("../images/fonts/GlacialIndifference-Italic.otf") format("woff"),
    url("../images/fonts/GlacialIndifference-Bold.otf") format("truetype");
}
a,
abbr,
acronym,
address,
applet,
article,
aside,
audio,
b,
big,
blockquote,
body,
canvas,
caption,
center,
cite,
code,
dd,
del,
details,
dfn,
div,
dl,
dt,
em,
embed,
fieldset,
figcaption,
figure,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
html,
i,
iframe,
img,
ins,
kbd,
label,
legend,
li,
mark,
menu,
nav,
object,
ol,
output,
p,
pre,
q,
ruby,
s,
samp,
section,
small,
span,
strike,
strong,
sub,
summary,
sup,
table,
tbody,
td,
textarea,
tfoot,
th,
thead,
time,
tr,
tt,
u,
ul,
var,
video {
  margin: 0;
  padding: 0;
  font: inherit;
  font-size: 100%;
  vertical-align: baseline;
  font-family: CustomFont, Anton, "Plus Jakarta Sans";
}
.font-plus,
input,
label,
textarea {
  font-family: CustomFont, "Plus Jakarta Sans";
}
.section3-ul li,
.text-16,
.text-font-anton,
body {
  font-family: CustomFont;
}
.layer-1-copy,
article,
aside,
details,
figcaption,
figure,
footer,
header,
menu,
nav,
section,
time {
  display: block;
}
a {
  color: inherit;
}
.section3-ul {
  list-style-type: circle !important;
  list-style-position: inside !important;
}
.section3-ul li {
  list-style: circle !important;
}
input:focus,
textarea:focus {
  outline: 0;
}
input {
  border-width: 0;
}
em {
  font-style: italic;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 0;
}
.group:after,
.group:before {
  content: " ";
  display: table;
}
.group:after {
  clear: both;
}
.group {
  zoom: 1;
}
embed,
img,
object,
video {
  max-width: 100%;
}
.no-space-between-inline-blocks {
  letter-spacing: normal;
  letter-spacing: -0.31em;
  word-spacing: -0.43em;
}
.no-space-between-inline-blocks > * {
  letter-spacing: normal;
  word-spacing: normal;
}
html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  font-size: 16px;
}
*,
:after,
:before {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
}
body {
  line-height: 1;
  background: #fff;
  color: #383838;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.04em;
}
.header {
  height: 1137px;
  position: relative;
  width: 100%;
}
.text {
  left: 50%;
  position: absolute;
  top: 627px;
  margin-left: -575px;
}
.col-9,
.rectangle-1-copy {
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.rectangle-1-copy {
  height: 1000px;
  background: #232222;
}
.col-9 {
  padding: 0 0 266px;
}
.l-constrained,
.l-constrained-2,
.l-constrained-3,
.l-constrained-4 {
  margin: 0 auto;
  position: relative;
}
.wrapper-15 {
  height: 164px;
  position: relative;
}
.layer-14,
.nav {
  position: absolute;
}
.layer-14 {
  left: 55%;
  top: 24px;
}
.rounded-rectangle-1-holder {
  height: 62px;
  width: 185px;
  border-radius: 7px;
  background: #cc0001;
  letter-spacing: 0.06em;
  line-height: 62px;
  text-align: center;
  cursor: pointer;
}
.nav {
  left: 48%;
  top: 96px;
  margin-left: -268px;
}
.nav-list {
  position: relative;
}
.rectangle-5,
.row-3,
.shape-6-2 {
  position: absolute;
}
.nav-list li {
  margin: 0 30px 0 0;
}
.nav-list li:last-child {
  margin-right: 0;
}
.nav-item-1,
.nav-item-1-2,
.nav-item-1-3,
.nav-item-1-4,
.nav-item-1-5,
.nav-item-1-6,
.nav-item-1-7 {
  color: #fff;
  font-size: 1.0625rem;
  letter-spacing: 0.06em;
  text-transform: uppercase;
}
.row-3 {
  display: flex;
  align-self: flex-end;
  min-height: 50px;
  padding: 12px 61px 13px;
  right: 0;
  width: 833px;
  background: url(../images/rectangle_4.png) no-repeat;
}
.iphone-22 {
  margin: 2px 0 0;
}
.text-3 {
  margin: 5px 0 0 16px;
}
.email-41 {
  margin: 6px 0 0 63px;
}
.text-4 {
  margin: 4px 0 0 19px;
}
.shape-6 {
  height: 20px;
  margin: 2px 0 0 26px;
  width: 1px;
  background: #e85e5e;
}
.facebook-29,
.text-31 {
  float: left;
  margin: 0 0 0 22px;
}
.instagram-30 {
  margin: 0 0 0 6px;
}
.linkedin-23 {
  margin: 0 0 0 7px;
}
.rectangle-5 {
  height: 5px;
  left: 50%;
  top: 0;
  width: 771px;
  background: #cc0001;
  margin-left: -800px;
}
.shape-6-2 {
  left: 50%;
  top: 4px;
  margin-left: -39px;
}
.wrapper-16 {
  height: 510px;
  margin: 92px 0 0 79px;
  position: relative;
  width: 853px;
}
.col,
.ellipse-1 {
  position: absolute;
}
.col {
  left: 45%;
  min-height: 503px;
  padding: 0 0 30px;
  top: 40px;
  width: 983px;
  margin-left: -376.5px;
}
.text-5 {
  text-align: left;
  margin: 0 0 0 147px;
  letter-spacing: 0.06em;
}
.text-6,
.text-7 {
  font-size: 5.8125rem;
  color: #fff;
}
.text-6 {
  margin: 4px 0 0 80px;
  text-align: center;
}
.text-7 {
  text-align: left;
  margin: 4px 0 0 137px;
  line-height: 112.2px;
  text-transform: uppercase;
}
.rounded-rectangle-1-copy-2-holder,
.text-9 {
  font-size: 1.0625rem;
  text-transform: uppercase;
}
.rounded-rectangle-1-copy-2-holder {
  margin: 4px 0 0 137px;
  min-height: 62px;
  padding: 0 46px;
  width: 210px;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  border-radius: 7px;
  background: #cc0001;
  color: #fff;
  letter-spacing: 0.06em;
  line-height: 62px;
  text-align: center;
}
.ellipse-1 {
  left: 42%;
  top: 0;
  margin-left: -287.5px;
}
.row-14 {
  margin: 31px 227px 0;
  position: relative;
}
.row-14 > * {
  margin: 0 10.13px 0 0;
}
.row-14 > :last-child,
.row-16 > :last-child,
.row-26 > :last-child {
  margin-right: 0 !important;
}
.rounded-rectangle-9-copy {
  height: 6px;
  width: 37px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  background: #fff;
}
.rounded-rectangle-9,
.rounded-rectangle-9-copy-2,
.rounded-rectangle-9-copy-2-2 {
  height: 6px;
  width: 37px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  background: rgba(255, 255, 255, 0.37);
}
.col-11 {
  left: 0;
  padding: 43px 0 25px;
  position: absolute;
  margin-top: -220px;
  width: 100%;
  background: #f1f1f1;
}
.row-16,
.row-17,
.wrapper-6 {
  position: relative;
}
.row-16 {
  margin: 0 auto;
  overflow: hidden;
}
.row-16 > * {
  margin: 0 24px 0 0;
}
.shape-4,
.shape-4-copy {
  height: 1px;
  width: 60px;
  background: #000;
}
.text-9 {
  color: #525252;
  letter-spacing: 0.14em;
}
.text-10,
.text-12 {
  color: #fff;
  font-size: 2.6875rem;
}
.services,
.text-10,
.text-12,
.text-14,
.text-27 {
  letter-spacing: 0.06em;
}
.row-17 {
  margin: -1px auto 0;
  text-align: center;
}
.Whatsappimage {
  filter: grayscale(100%);
}
.Whatsappimage:hover {
  filter: none;
}
.row-17 > * {
  display: inline-block;
  vertical-align: top;
}
.text-11,
.text-13,
.text-16,
.text-19 {
  display: block;
}
.main-content-wrapper {
  margin: 68px 0 0;
  position: relative;
}
.rounded-rectangle-2 {
  left: 55%;
  position: absolute;
  top: 58px;
  width: 582px;
  -webkit-border-radius: 35px;
  -moz-border-radius: 35px;
  border-radius: 35px;
  margin-left: -260px;
}
.col-2 {
  left: 55%;
  min-height: 204px;
  padding: 32px 29px 36px;
  position: absolute;
  top: 0;
  width: 215px;
  border: 13px solid #fff;
  -webkit-border-radius: 48px;
  -moz-border-radius: 48px;
  border-radius: 48px;
  background: padding-box #cc0001;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  margin-left: 151px;
}
.text-10 {
  margin: 0 0 0 1px;
  text-transform: uppercase;
}
.text-11 {
  margin: 23px 0 0;
  position: relative;
}
.col-3 {
  left: 55%;
  min-height: 144px;
  padding: 20px 39px;
  position: absolute;
  top: 75%;
  width: 405px;
  border: 13px solid #fff;
  -webkit-border-radius: 48px;
  -moz-border-radius: 48px;
  border-radius: 48px;
  background: padding-box #cc0001;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  margin-left: -366px;
}
.col-10,
.col-23,
.row-18,
.row-21,
.text-13,
.text-16 {
  position: relative;
}
.text-12 {
  text-transform: uppercase;
}
.services,
.text-14,
.text-15 {
  color: #000;
  text-transform: uppercase;
}
.text-13 {
  margin: 16px 0 0;
}
.col-10 {
  margin: 115px 0 0;
}
.services,
.text-14 {
  font-size: 1.0625rem;
}
.text-15 {
  margin: 11px 0 0;
  font-size: 3.5625rem;
  line-height: 71.2px;
}
.text-16 {
  margin: 45px 0 0;
}
.rounded-rectangle-1-copy-3-holder {
  margin: 42px 0 0;
  min-height: 62px;
  padding: 0 23px;
  position: relative;
  width: 215px;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  border-radius: 7px;
  background: #cc0001;
  color: #fff;
  font-size: 1.0625rem;
  letter-spacing: 0.06em;
  line-height: 62px;
  text-align: center;
  text-transform: uppercase;
}
.row,
.row-2 {
  min-height: 289px;
}
.text-18,
.text-25 {
  color: #000;
  text-transform: uppercase;
}
.row-18 {
  margin: 99px 0 0;
  padding: 92px 0 84px;
  background: #f1f1f1;
}
.text-18 {
  margin: 9px 0 0;
  font-size: 3.5625rem;
}
.text-19 {
  margin-top: 30px;
  margin-left: 14px;
}
.row-21 {
  margin: 57px 7px 0;
}
.col-23 {
  margin: 0 21px 0 0;
  width: 182px;
}
.col-21,
.wrapper-23 {
  position: relative;
  width: 188px;
}
.gardener {
  margin: 0 11px 0 0;
}
.leaf-1 {
  margin: 0 28px 0 0;
}
.text-21 {
  margin: 4px 0 0;
}
.click-51-copy {
  display: block;
  left: 35.5px;
  margin: -29px auto 0;
  position: relative;
}
.snowflake-3 {
  margin: 0 15px 0 0;
}
.winterization {
  margin: 13px 0 0;
}
.roller-brush {
  margin: 0 18px 0 0;
}
.text-22,
.text-55 {
  margin: 3px 0 0;
}
.wrapper-23 {
  height: 108px;
  margin: 17px 0 0;
}
.rounded-rectangle-6-copy-4 {
  height: 118px;
  right: 5px;
  position: absolute;
  top: 0;
  width: 118px;
  -webkit-border-radius: 19.83px/19.87px;
  -moz-border-radius: 19.83px/19.87px;
  border-radius: 19.83px/19.87px;
  background: #cc0001;
  margin-left: -3px;
}
.text-23 {
  float: right;
  margin: 7px 5px 0 0;
}
.text-24 {
  float: right;
  margin: 6px 0 0;
}
.col-19,
.wrapper-8 {
  float: left;
}
.wrapper-8 {
  height: 697px;
  position: relative;
}
.rounded-rectangle-11 {
  height: 348px;
  position: absolute;
  top: 0;
  width: 332px;
  -webkit-border-radius: 40px;
  -moz-border-radius: 40px;
  border-radius: 40px;
  background: #cc0001;
}
.rectangle-6-holder {
  border-radius: 20px;
  left: 51%;
  position: absolute;
  top: 28px;
  width: 584px;
  margin-left: -270px;
}
.col-14,
.col-19,
.contractors,
.row-23,
.row-24 {
  position: relative;
}
.col-14 {
  margin: 0 7px 15px;
  padding-left: 15px;
  padding-top: 15px;
  padding-bottom: 15px;
  width: 100%;
  background-color: #fff;
  border-radius: 40px;
}
.row-24 {
  margin: 0 0 0 4px;
}
.text-25 {
  font-size: 1.3125rem;
}
.l-unconstrained,
.text-26 {
  margin: 20px 0 0;
  position: relative;
}
.remodeling-copy {
  float: right;
  margin: 17px 0 0;
}
.text-27 {
  margin: 19px 0 0 5px;
  color: #cc0001;
  font-size: 0.875rem;
  text-transform: uppercase;
}
.col-12 {
  margin-top: -187px;
  padding: 68px 0 130px;
  width: 100%;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  object-fit: cover;
}
.contractors {
  color: #cc0001;
  font-size: 125px;
  letter-spacing: 0;
  text-transform: uppercase;
  text-align: left;
  margin-left: -3px;
}
.texas-2,
.text-28 {
  color: #fff;
  text-transform: uppercase;
}
.row-23 {
  display: table;
  margin: 21px auto 0 0;
}
.text-28 {
  float: left;
  font-size: 2.9375rem;
}
.shape-3 {
  float: left;
  height: 112px;
  margin: 4px 50px 0 100px;
  width: 1px;
  background: #fff;
}
.rounded-rectangle-3-copy-2-holder,
.rounded-rectangle-3-copy-6 {
  height: 289px;
  -webkit-border-radius: 0 35px 35px 0;
  -moz-border-radius: 0 35px 35px 0;
  position: absolute;
}
.rounded-rectangle-3-copy-2-holder {
  top: 77px;
  width: 200px;
  border-radius: 0 35px 35px 0;
  background: #cc0001;
  margin-left: -85px;
}
.text-30 {
  margin-left: -78.5px;
}
.rounded-rectangle-3-copy-6 {
  left: 50%;
  top: 0;
  width: 180px;
  border-radius: 0 35px 35px 0;
  background: rgba(29, 21, 14, 0.69);
  margin-left: -78.5px;
}
.rounded-rectangle-3-copy-7 {
  height: 179px;
  margin: 0 0 0 -6px;
  width: 101px;
  -webkit-border-radius: 0 35px 35px 0;
  -moz-border-radius: 0 35px 35px 0;
  border-radius: 0 35px 35px 0;
  background: rgba(16, 15, 17, 0.75);
}
.top-copy {
  left: 50%;
  position: absolute;
  top: 200px;
  margin-left: 43px;
}
.col-13,
.col-24,
.col-25,
.col-28,
.col-6,
.rounded-rectangle-3-copy-4,
.row,
.row-10,
.row-19,
.row-2,
.row-20,
.row-26,
.row-31,
.row-32 {
  position: relative;
}
.col-24 {
  float: left;
  margin: 0 0 0 10px;
  width: 384px;
  z-index: 0;
}
.rounded-rectangle-3-copy-3 {
  display: block;
  margin: 0 auto;
  position: relative;
  width: 336px;
  z-index: 7;
  border: 6px solid #cc0001;
  -webkit-border-radius: 41px;
  -moz-border-radius: 41px;
  border-radius: 41px;
  -webkit-box-shadow: 0 18px 21px 0 rgba(0, 0, 0, 0.27);
  -moz-box-shadow: 0 18px 21px 0 rgba(0, 0, 0, 0.27);
  box-shadow: 0 18px 21px 0 rgba(0, 0, 0, 0.27);
}
.row {
  margin: -114px auto 0;
  padding: 164px 39px 44px;
  z-index: 5;
  -webkit-border-radius: 35px;
  -moz-border-radius: 35px;
  border-radius: 35px;
  background: #cc0001;
}
.shape-2 {
  float: left;
  height: 67px;
  margin: 3px 0 0 17px;
  width: 1px;
  background: #e6fcfb;
}
.top {
  float: right;
  margin: 200px 0 0;
}
.col-13,
.col-25,
.row-26 > *,
.texas-2,
.text-41,
.text-45 {
  float: left;
}
.rounded-rectangle-3-copy-4 {
  display: block;
  margin: 0 auto;
  width: 336px;
  z-index: 40;
  border: 6px solid #cc0001;
  -webkit-border-radius: 41px;
  -moz-border-radius: 41px;
  border-radius: 41px;
  -webkit-box-shadow: 0 18px 21px 0 rgba(0, 0, 0, 0.27);
  -moz-box-shadow: 0 18px 21px 0 rgba(0, 0, 0, 0.27);
  box-shadow: 0 18px 21px 0 rgba(0, 0, 0, 0.27);
}
.rounded-rectangle-3-copy-4:hover {
  filter: brightness(50%);
}
.row-2 {
  margin: -114px auto 0;
  padding: 150px 20px 55px;
  z-index: 9;
  -webkit-border-radius: 35px;
  -moz-border-radius: 35px;
  border-radius: 35px;
  background: #cc0001;
}
.texas-2 {
  margin: 17px 0 0;
  font-size: 1.625rem;
  letter-spacing: 0;
}
.shape-2-copy {
  float: left;
  height: 67px;
  margin: 0 0 0 20px;
  width: 1px;
  background: #e6fcfb;
}
.text-33,
.text-34 {
  color: #000;
  font-size: 3.5625rem;
  line-height: 58px;
  text-transform: uppercase;
}
.rounded-rectangle-1-copy-4-holder {
  width: 185px;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  border-radius: 7px;
  background: #cc0001;
  color: #fff;
  font-size: 1.0625rem;
  letter-spacing: 0.06em;
  line-height: 62px;
  text-align: center;
  text-transform: uppercase;
}
.rounded-rectangle-1-copy-4-holder-form,
.rounded-rectangle-1-copy-4-holder-light {
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  text-transform: uppercase;
  width: 185px;
  color: #fff;
  font-size: 1.0625rem;
  letter-spacing: 0.06em;
  text-align: center;
}
.rounded-rectangle-1-copy-4-holder-form {
  border-radius: 7px;
  background: #cc0001;
}
.rounded-rectangle-1-copy-4-holder-light {
  border-radius: 7px;
  line-height: 62px;
}
.rounded-rectangle-1-copy-4-holder-light-form {
  width: 185px;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  border-radius: 7px;
  color: #fff;
  font-size: 1.0625rem;
  letter-spacing: 0.06em;
  text-align: center;
  text-transform: uppercase;
}
.faq,
.text-36,
.text-38 {
  color: #000;
  text-transform: uppercase;
}
.text-36 {
  font-size: 1.0625rem;
  letter-spacing: 0.06em;
  line-height: 18px;
}
.faq {
  font-size: 1.0625rem;
  letter-spacing: 0.06em;
  text-align: center;
}
.text-38 {
  margin: 15px 0 0;
  font-size: 3.5625rem;
  text-align: center;
}
.row-10 {
  display: flex;
  margin: 24px auto 0;
  min-height: 60px;
  padding: 10px 18px 20px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  border-radius: 15px;
  background: #f3f3f3;
}
.text-41 {
  margin: 11px 1px 0 32px;
  width: 100%;
}
.row-19 {
  margin: 18px auto 0;
  min-height: 154px;
  padding: 5px 24px 41px 51px;
  width: 814px;
  border: 2px solid #cc0001;
  -webkit-border-radius: 17px;
  -moz-border-radius: 17px;
  border-radius: 17px;
  -webkit-box-shadow: 0 3px 16px 0 rgba(0, 0, 0, 0.17);
  -moz-box-shadow: 0 3px 16px 0 rgba(0, 0, 0, 0.17);
  box-shadow: 0 3px 16px 0 rgba(0, 0, 0, 0.17);
}
.col-13 {
  margin: 15px 0 0;
}
.text-45 {
  width: 12px;
  font-size: 1.8125rem;
}
.col-6 {
  height: 450px;
  margin: 84px 0 0;
  padding: 49px 0 23px;
  background: url(../images/rectangle_8_copy_2.jpg) center 0 / cover no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
}
.row-20 {
  width: 1070px;
}
.col-25 {
  width: 94px;
}
.row-26 {
  margin: 49px 0 0;
}
.row-26 > * {
  margin: 0 6.3px 0 0;
}
.col-28 {
  float: right;
  margin: 14px 0 0;
  width: 176px;
}
.services-2,
.text-50,
.text-53 {
  color: #fff;
  font-size: 1.4375rem;
  letter-spacing: 0;
  text-transform: uppercase;
}
.row-31 {
  margin: 43px 0 0 7px;
}
.pin-67 {
  float: left;
  margin: -5px 13px 0 -4px;
}
.row-32 {
  margin: 28px 9px 0;
}
.row-33 {
  display: table;
  margin: 23px auto 0;
  position: relative;
}
.text-51,
.text-52,
.text-57 {
  display: block;
  position: relative;
}
.col-27 {
  float: right;
  margin: 14px 88px 0 0;
  position: relative;
  width: 201px;
}
.text-52 {
  margin: 43px 0 0 2px;
}
.col-26 {
  float: right;
  margin: 15px 154px 0 0;
  position: relative;
  width: 110px;
}
.text-51 {
  margin: 42px 0 0;
}
.shape-10 {
  height: 1px;
  margin: 58px 0 0 2px;
  position: relative;
  background: #878787;
}
.text-57 {
  margin: 26px auto 0;
  right: 9px;
}
.colorcc0001 {
  color: #cc0001;
}
.text-font-anton {
  opacity: 0.1;
}
.text-font-plus-jakarta {
  font-family: CustomFont;
  opacity: 0.9;
}
.carousel.carousel-slider {
  height: 1000px;
}
@media only screen and (max-width: 1024px) {
  .col,
  .col-11,
  .rounded-rectangle-2,
  .row-3 {
    position: relative;
  }
  .header {
    height: 500px;
  }
  .rectangle-1-copy {
    height: 530px;
  }
  .carousel.carousel-slider,
  .row-15,
  .wrapper-6 {
    height: auto;
  }
  .rounded-rectangle-1-copy-3-holder {
    margin: 25px 0 0;
  }
  .contractors {
    text-align: center;
    font-size: 40px;
    margin-bottom: 10px;
  }
  .text-5,
  .text-6,
  .text-7 {
    margin: 0;
  }
  .text-6,
  .text-7 {
    text-align: left;
    font-size: 40px;
  }
  .text-5,
  .text-7 {
    text-align: center;
  }
  .text-7 {
    padding-top: 10px;
    line-height: 50px;
  }
  .text-5 {
    line-height: 60px;
    font-size: 40px;
  }
  .row-15 {
    padding: 10px;
  }
  .rounded-rectangle-2 {
    width: 100%;
    left: 0;
    top: 0;
    margin-left: 0;
  }
  .rounded-rectangle-1-copy-2-holder {
    margin: 10px 0 20px;
    position: relative;
    left: 0;
    min-height: 20px;
    height: 60px;
    width: 230px;
    padding: 10px 50px;
    display: flex;
    font-size: 20px;
    align-items: center;
    align-self: center;
  }
  .col,
  .col-14 {
    min-height: auto;
  }
  .col-14,
  .col-19,
  .row-24 {
    width: 100%;
  }
  .wrapper-8 {
    padding: 10px;
    height: 557px;
  }
  .col-14 {
    margin-top: 50px;
    padding: 20px 30px;
    border-bottom-right-radius: 45px;
  }
  .rectangle-6-holder {
    width: 100%;
    margin-left: 0;
    left: 0;
  }
  .row-24 {
    display: flex;
  }
  .remodeling-copy {
    margin-left: 10px;
    margin-right: 10px;
    height: 70px;
    width: auto;
  }
  .rounded-rectangle-11,
  .shape-3,
  .top {
    display: none;
  }
  .col,
  .col-3,
  .nav-list,
  .row-14,
  .row-16 {
    display: flex;
  }
  .l-constrained-2,
  .row-19 {
    padding: 10px;
  }
  .col,
  .col-9 {
    padding: 0;
  }
  .rounded-rectangle-1-holder {
    left: 1%;
    margin-left: 0;
  }
  .text-33,
  .text-34,
  .text-36,
  .text-38 {
    font-size: 30px;
    line-height: 40px;
  }
  .text-41 {
    margin: 2px;
  }
  .col-13,
  .row-19 {
    margin: 2px;
    width: 100%;
  }
  .rounded-rectangle-1-copy-4-holder-light {
    width: 100px;
    font-size: 20px;
  }
  .nav-list {
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
  .nav-list li {
    margin-right: 15px;
  }
  .nav-item-1,
  .nav-item-1-2,
  .nav-item-1-3,
  .nav-item-1-4,
  .nav-item-1-5,
  .nav-item-1-6,
  .nav-item-1-7 {
    margin-bottom: 10px;
    font-size: 10px;
  }
  .rounded-rectangle-1-holder {
    height: 42px;
    width: 105px;
    border-radius: 7px;
    font-size: 10px;
    letter-spacing: 0.06em;
    line-height: 22px;
    text-align: center;
    cursor: pointer;
    text-transform: uppercase;
  }
  .wrapper-16 {
    width: 100%;
    height: 100%;
    margin: 30px 0 0;
  }
  .col {
    top: 0;
    bottom: 0;
    left: 0;
    margin-left: 0;
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .row-14 {
    justify-content: center;
    align-items: center;
    margin: 0 0 10px;
  }
  .wrapper-15 {
    height: 164px;
  }
  .row-3 {
    padding: 10px;
    width: auto;
    background: red;
    justify-content: center;
  }
  .facebook-29,
  .shape-6 {
    margin-left: 10px;
  }
  .col-11 {
    margin-top: 15px;
    padding: 20px 0;
  }
  .text-9 {
    font-size: 12px;
    line-height: 25px;
  }
  .text-18 {
    font-size: 32px;
    margin-top: 20px;
  }
  .row-16 {
    width: 100%;
    justify-items: center;
    align-items: center;
  }
  .Whatsappimage {
    width: 15%;
  }
  .col-2 {
    margin-top: -40px;
    right: 0;
    padding: 20px;
    min-height: 100px;
    width: 200px;
    margin-left: 0;
  }
  .col-3 {
    flex-direction: column;
    justify-self: center;
    width: auto;
    top: 85%;
    left: 5%;
    margin: 10px;
  }
  .col-12,
  .rounded-rectangle-1-copy-4-holder {
    justify-content: center;
    display: flex;
  }
  .col-10 {
    margin: 80px 0 0;
  }
  .row-18 {
    margin: 0;
    padding: 50px 10px 10px;
  }
  .text-33,
  .text-34,
  .text-36 {
    margin-left: 0;
  }
  .col-12 {
    padding: 0;
    margin-top: 0;
    align-items: center;
    flex-direction: column;
  }
  .contractors {
    margin-top: 40px;
  }
  .row-23 {
    margin: 0px;
    padding: 0 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .text-28 {
    text-align: center;
    width: 100%;
    font-size: 22px;
    line-height: 40px;
  }
  .col-24 {
    margin-left: 15px;
    width: 378px;
  }
  .col-6 {
    height: 100%;
  }
  .l-unconstrained {
    margin: 100px 0 0;
  }
}
@media only screen and (max-width: 767px) {
  .wrapper-15 {
    height: 70px;
  }
}
@media only screen and (max-width: 500px) {
  .l-unconstrained {
    margin: 70px 0 0;
  }
  .header {
    height: 300px;
  }
  .rectangle-1-copy {
    height: 330px;
  }
  .wrapper-15 {
    height: auto;
  }
  .text-6,
  .text-7 {
    text-align: left;
    font-size: 15px;
  }
  .text-5,
  .text-7 {
    text-align: center;
    margin: 0;
  }
  .text-6 {
    margin: 0;
  }
  .text-7 {
    padding-top: 10px;
    line-height: 20px;
  }
  .text-5 {
    line-height: 30px;
    font-size: 15px;
  }
  .rounded-rectangle-1-copy-2-holder {
    margin: 20px 0 20px;
    position: relative;
    left: 0;
    min-height: 20px;
    height: 40px;
    width: 118px;
    padding: 0 20px;
    display: flex;
    font-size: 12px;
    align-items: center;
    align-self: center;
  }
  .wrapper-16 {
    width: 100%;
    height: 100%;
    margin: 0;
  }
  .text-36 {
    font-size: 1.0625rem;
  }
  .text-33,
  .text-34,
  .text-38 {
    font-size: 28px;
    line-height: 35px;
  }
  .col-24 {
    margin-left: 15px;
    width: 350px;
  }
  .col-2 {
    margin-top: -30px;
    left: 63%;
    padding: 20px;
    min-height: 100px;
    width: 150px;
  }
  .col-3 {
    display: flex;
    flex-direction: column;
    justify-self: center;
    width: auto;
    top: 80%;
    left: -3%;
    margin: 10px;
  }
  .col-11 {
    margin-top: 0px;
  }
}
.section4 .carousel.carousel-slider,
.section6 .carousel.carousel-slider {
  display: flex;
}
.remodeling-copy-1 {
  width: 50px !important;
  position: absolute;
}
.filter-gray {
  filter: brightness(30%);
}
.button-contianer {
  display: flex;
  justify-content: space-between;
}
.img-container {
  display: flex;
  overflow: hidden;
  scroll-behavior: smooth;
  width: 1150px;
}
@media only screen and (max-width: 1024px) {
  .img-container {
    width: 720px;
  }
}
@media only screen and (min-width: 1624px) {
  .img-container {
    width: 1450px;
  }
}
@media only screen and (min-width: 2024px) {
  .img-container {
    width: 1445px;
  }
  .right-button {
    right: 25%;
  }
}
@media only screen and (min-width: 2224px) {
  .img-container {
    width: 1524px;
  }
}
